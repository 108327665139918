<template>
  <div>
    <PageHeader>
      <div class="flex items-center">
        <router-link
          to="profile"
          class="px-2 -ml-2 py-1 inline-block focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </router-link>
      </div>

      <h1 class="font-medium">Password</h1>

      <!-- <div class="flex items-center justify-end">
        <router-link
          to="projects"
          class="opacity-75 text-right focus:outline-none py-1 px-2 -mr-2"
        >
          Projects
        </router-link>
      </div> -->
    </PageHeader>

    <ToastNotification :message="toastMessage"></ToastNotification>

    <main class="mt-4 px-6">
      <div class="text-center">
        <h2 class="text-xl">Change password</h2>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form class="mt-8" @submit.prevent="handleSubmit(submit)" novalidate>
            <input
              v-if="currentUser.email"
              class="hidden"
              :value="currentUser.email"
              type="email"
              name="email"
              id="email"
              autocomplete="email"
              placeholder="email"
            />
            <ValidationProvider
              name="New password"
              rules="required|min:8"
              v-slot="{ errors }"
            >
              <div class="mt-6 relative">
                <input
                  :type="inputFieldTypes.new_password1"
                  name="new-password"
                  placeholder="New password"
                  aria-label="New password"
                  id="new-password"
                  autocomplete="new-password"
                  class="block w-full px-0 border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                  v-model="inputs.new_password1"
                />
                <button
                  type="button"
                  v-show="inputs.new_password1.length"
                  @click.prevent="switchVisibility('new_password1')"
                  class="absolute right-0 top-0 bottom-0"
                >
                  <svg
                    v-show="inputFieldTypes.new_password1 === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="h-5"
                  >
                    <path
                      d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                    ></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                  <svg
                    v-show="inputFieldTypes.new_password1 === 'text'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="h-5"
                  >
                    <path
                      d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
                    ></path>
                    <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                </button>
              </div>
              <div
                class="h-6 text-sm flex items-center text-red-500 font-light text-left"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <ValidationProvider
              rules="required|min:8|password:@New password"
              name="Confirm new password"
              v-slot="{ errors }"
            >
              <div class="mt-1 relative">
                <input
                  :type="inputFieldTypes.new_password2"
                  name="new-password"
                  placeholder="Repeat new password"
                  aria-label="Repeat new password"
                  id="repeat-new-password"
                  autocomplete="new-password"
                  class="mt-1 block w-full px-0 border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                  v-model="inputs.new_password2"
                />
                <button
                  type="button"
                  v-show="inputs.new_password2.length"
                  @click.prevent="switchVisibility('new_password2')"
                  class="absolute right-0 top-0 bottom-0"
                >
                  <svg
                    v-show="inputFieldTypes.new_password2 === 'password'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="h-5"
                  >
                    <path
                      d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                    ></path>
                    <circle cx="12" cy="12" r="3"></circle>
                  </svg>
                  <svg
                    v-show="inputFieldTypes.new_password2 === 'text'"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="h-5"
                  >
                    <path
                      d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
                    ></path>
                    <line x1="1" y1="1" x2="23" y2="23"></line>
                  </svg>
                </button>
              </div>
              <div
                class="h-6 text-sm flex items-center text-red-500 font-light text-left"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
            <button
              type="submit"
              class="mt-6 w-56 py-3 border border-black rounded-full outline-none"
            >
              Change
            </button>
          </form>
        </ValidationObserver>
      </div>
    </main>
  </div>
</template>

<script>
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { messages } from "vee-validate/dist/locale/en.json";
import { min, required } from "vee-validate/dist/rules";
import { mapActions, mapState } from "vuex";

import PageHeader from "@/components/PageHeader";
import ToastNotification from "@/components/ToastNotification.vue";

setInteractionMode("eager");
extend("required", { ...required, message: messages["required"] });
extend("min", {
  ...min,
  message: "The password must be at least {length} characters",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Oops, they are not the same!",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    PageHeader,
    ToastNotification,
  },
  data() {
    return {
      inputs: {
        new_password1: "",
        new_password2: "",
      },
      inputFieldTypes: {
        new_password1: "password",
        new_password2: "password",
      },
      toastMessage: { type: "", content: "" },
    };
  },
  computed: {
    ...mapState("staff", ["currentStaff"]),
    ...mapState("users", ["currentUser"]),
  },
  async created() {
    if (Object.keys(this.currentStaff).length === 0) {
      await this.getCurrentStaff();
    }

    if (Object.keys(this.currentUser).length === 0) {
      await this.getUserByUrl(this.currentStaff.user);
    }
  },
  methods: {
    ...mapActions("staff", ["getCurrentStaff"]),
    ...mapActions("users", ["getUserByUrl"]),
    ...mapActions("auth", ["changePassword"]),
    submit() {
      this.toastMessage = { type: "", content: "" };
      this.changePassword(this.inputs)
        .then(() => {
          this.inputs = {
            new_password1: "",
            new_password2: "",
          };

          this.toastMessage = {
            type: "success",
            content: "Password changed correctly.",
          };

          this.$nextTick(() => {
            this.$refs.form.reset();
          });
        })
        .catch((error) => {
          const errorData = error.response.data;
          const toastMessage = errorData[Object.keys(errorData)[0]][0];

          this.toastMessage.type = "error";

          this.toastMessage.content = toastMessage.length
            ? toastMessage
            : "There was an error changing the password";
        });
    },
    switchVisibility(field) {
      this.inputFieldTypes[field] =
        this.inputFieldTypes[field] === "password" ? "text" : "password";
    },
  },
};
</script>
